<template>
	<!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a href="#" class="nav__btn nav__btn--prev">
				<i class="icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__btn">
					<i class="icon icon-location"></i>
				</span>
				<span class="nav__text">
					강남 논현 삼성
				</span>
			</p>
			<a href="#" class="nav__btn nav__btn--map">
				<i class="icon icon-map"></i>
			</a>
		</div>
	</nav>
	<!-- //nav-->
	<div id="container" class="container container--fit">
		<div id="sub" class="sub inn">
			<!-- category -->
			<section class="listsearch">
				<div class="listsearch__wrap">
					<div class="listsearch__inner">
						<div class="listsearch__box">
							<span class="listsearch__icon">
								<i class="icon icon-date"></i>
							</span>
							<input type="text" class="listsearch__ipt" value="11.11(목)~11.12(금)" readonly>
						</div>
					</div>
					<div class="listsearch__inner">
						<div class="listsearch__box">
							<span class="listsearch__icon">
								<i class="icon icon-member"></i>
							</span>
							<input type="text" class="listsearch__ipt" value="성인 2명, 아동 2명" readonly>
						</div>
					</div>
				</div>
			</section>
			<!-- content -->
			<section class="content">
				<div class="listbox">
					<div class="box">
						<div class="list__meta">
							<div class="list__check">
								<div class="checkbox">
									<label>
										<input type="checkbox" class="chk blind">
										<span class="checkbox__bg"></span>
										쿠폰할인 가능한 숙소
									</label>
								</div>
							</div>
							<div class="list__option">
								<button type="button">
									<i class="icon icon-align">
										정렬
									</i>
								</button>
								<button type="button">
									<i class="icon icon-filter">
										필터
									</i>
								</button>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="centered" style="height:calc(100vh - 280px)">
							<div class="centered__wrap">
								<div class="textbox">
									<div class="textbox__icon">
										<img src="@/assets/images/img_warning.png" alt="숙소 없음">
									</div>
									<h3 class="textbox__title">
										조건에 맞는 숙소가 없어요
									</h3>
									<p class="textbox__description">
										지역이나 날짜, 인원을 변경해보세요
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
</template>